import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import HeaderImage from "../components/HeaderImage";
import Footer from "../components/Footer";
import Information from "../components/Information";
import styles from "../css/pagestructor.module.scss";

import content from "../content/cookiepolicy/content.json";

const Events = ({ navBar, props }) => {
  const { toggle, setToggle, offset, width } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar width={width} offset={offset} toggle={toggle} setToggle={setToggle} navBar={navBar} />
      <div style={{ backgroundColor: "white", overflow: "hidden" }}>
        {!toggle && (
          <>
            <HeaderImage header={content.header} />
            <div className={styles.marginOnPage}>
              <div className={`${styles.flexColWrapper}`}>
                <Information info={content.information[0]} />
              </div>
              <div className={`${styles.flexColWrapper} ${styles.grayBackground}`}>
                <Information info={content.information[1]} />
              </div>
              <div className={`${styles.flexColWrapper}`}>
                <Information info={content.information[2]} />
              </div>
              <div className={`${styles.flexColWrapper} ${styles.grayBackground}`}>
                <Information info={content.information[3]} />
              </div>
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default Events;
