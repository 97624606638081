import * as React from "react";
import styles from "../css/pagestructor.module.scss";

// markup
const NotFoundPage = () => {
  return (
    <>
      <div className={styles.marginOnPage}></div>
    </>
  );
};

export default NotFoundPage;
