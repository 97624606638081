import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./styles/global.scss";

const App = () => {
  const [toggle, setToggle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(0);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth > 900) {
      setToggle(false);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  /* Fetching Jobs*/
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_GCP_BUCKET_JOBS_URL + "/jobs.json");
      const data = await response.json();
      setJobs(data);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <BrowserRouter>
      <Routes jobs={jobs} setToggle={setToggle} toggle={toggle} offset={offset} width={width} />
    </BrowserRouter>
  );
};
export default App;
