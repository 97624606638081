import React from "react";
import styles from "../css/applynow.module.scss";

const ApplyNowButton = ({ avatureLink, title }) => {
  return (
    <a className={`${styles.applyNow} externalLink`} href={avatureLink}>
      {title}
    </a>
  );
};

const ApplyNow = ({ avatureLink }) => {
  if (typeof avatureLink === "string") {
    return (
      <>
        <ApplyNowButton avatureLink={avatureLink} title={"Apply Now"} />
      </>
    );
  }

  return (
    <>
      {avatureLink.map((data, index) => (
        <ApplyNowButton avatureLink={data.url} title={data.title} key={index} />
      ))}
    </>
  );
};

export default ApplyNow;
