import React, { useEffect } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import Home from "./pages/home";
// import Locations from "./pages/locations";
// import WorkWithUs from "./pages/workwithus";
// import CultureValues from "./pages/culturevalues";
import Error404 from "./pages/404";
// import Newsroom from "./pages/newsroom";
// import Jobs from "./pages/jobs";
import CookiePolicy from "./pages/cookiepolicy";
// import RecruitmenProcess from "./pages/recruitmentprocess";

// import Accelerator from "./pages/accelerator";
// import Accelerator_de from "./pages/accelerator/ds";
// import Accelerator_sw from "./pages/accelerator/sw";
// import Accelerator_sy from "./pages/accelerator/sy";
// import Accelerator_ce from "./pages/accelerator/ce";

// import Sweden from "./pages/locations/sweden";
// import China from "./pages/locations/china";
// import India from "./pages/locations/india";
// import Netherlands from "./pages/locations/netherlands";
// import Spain from "./pages/locations/spain";
// import USA from "./pages/locations/usa";

import { logAnalytics, addEventListenersForExternalLinks } from "./analytics";
import { v4 as uuidv4 } from "uuid";
import usePrevious from "./hooks/usePrevious";
// import Student from "./pages/student";
// import StudentOpportunities from "./pages/studentopportunities";
type Props = {
  jobs: any;
  setToggle: any;
  toggle: any;
  offset: any;
  width: any;
};

const sessionId = uuidv4();
sessionStorage.setItem("sessionId", sessionId);

const Routes = ({ toggle, setToggle, offset, width, jobs }: Props) => {
  const location = useLocation();
  const previousLocation = usePrevious(location.pathname);

  useEffect(() => {
    (async () => {
      addEventListenersForExternalLinks(location.pathname, previousLocation);
      await logAnalytics(location.pathname, previousLocation);
    })();
  }, [location, previousLocation]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Home
            navBar={"home"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width, jobs: jobs }}
          />
        )}
      />
      {/* <Route
        exact
        path="/locations"
        render={() => (
          <Locations
            navBar={"locations"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      />
      <Route
        exact
        path="/work-with-us"
        render={() => (
          <WorkWithUs
            navBar={"work-with-us"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* Accelerator Programs*/}
      {/* <Route
        exact
        path="/accelerator"
        render={() => (
          <Accelerator
            navBar={"accelerator"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/accelerator/ds"
        render={() => (
          <Accelerator_de
            navBar={"accelerator"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/accelerator/sw"
        render={() => (
          <Accelerator_sw
            navBar={"accelerator"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/accelerator/sy"
        render={() => (
          <Accelerator_sy
            navBar={"accelerator"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/accelerator/ce"
        render={() => (
          <Accelerator_ce
            navBar={"accelerator"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}

      {/*<Route exact path="/events" render={() => <Events navBar={"events"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />} />*/}
      {/* <Route
        exact
        path="/newsroom"
        render={() => (
          <Newsroom
            navBar={"newsroom"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      /> */}
      {/* <Route
        exact
        path="/culture-values"
        render={() => (
          <CultureValues
            navBar={"culture-values"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      />
      <Route
        exact
        path="/jobs"
        render={() => (
          <Jobs
            navBar={"jobs"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width, jobs: jobs }}
          />
        )}
      />
      <Route
        exact
        path="/student"
        render={() => (
          <Student
            navBar={"student"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width, jobs: jobs }}
          />
        )}
      />
      <Route
        exact
        path="/student/opportunities"
        render={() => (
          <StudentOpportunities
            navBar={"student"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width, jobs: jobs }}
          />
        )}
      /> */}
      <Route exact path="/404" render={() => <Error404 />} />
      {/* Country */}
      {/* <Route
        exact
        path="/locations/sweden"
        render={() => (
          <Sweden navBar={"locations"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      />
      <Route
        exact
        path="/locations/china"
        render={() => (
          <China navBar={"locations"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      />
      <Route
        exact
        path="/locations/india"
        render={() => (
          <India navBar={"locations"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      />
      <Route
        exact
        path="/locations/netherlands"
        render={() => (
          <Netherlands
            navBar={"locations"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      />
      <Route
        exact
        path="/locations/spain"
        render={() => (
          <Spain navBar={"locations"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      />
      <Route
        exact
        path="/locations/usa"
        render={() => (
          <USA navBar={"locations"} props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      /> */}
      {/* CookiePolicy */}
      <Route
        exact
        path="/cookiepolicy"
        render={() => (
          <CookiePolicy
            navBar={"cookiepolicy"}
            props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }}
          />
        )}
      />
      {/*
        The page 'recruitment-process' might be embedded on an external site.
        And must therefore be able to be rendered without the navbar.
      */}
      {/* <Route
        exact
        path="/recruitment-process"
        render={() => (
          <RecruitmenProcess props={{ toggle: toggle, setToggle: setToggle, offset: offset, width: width }} />
        )}
      /> */}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Routes;
