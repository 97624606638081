import React, { useEffect } from "react";

import NavBar from "../components/NavBar";
// import HeaderVideo from "../components/HeaderVideo";
// import IkeaCounters from "../components/IkeaCounters";
// import Information from "../components/Information";
// import Footer from "../components/Footer";
// import ImageRound from "../components/ImageRound";
// import JoinUs from "../components/JoinUs";
// import Screws from "../components/Screw";
// import JobCards from "../components/JobCards";
// import VideoLinkin from "../components/VideoLinkin";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/focus/dist/style.css";
import Button from "@ingka/button";
import arrowRight from "@ingka/ssr-icon/paths/arrow-right";

// import "@ingka/image/dist/style.css";
// import "@ingka/aspect-ratio-box/dist/style.css";
// import "@ingka/card/dist/style.css";
// import Hello from "../components/Hello";
// import Card from "@ingka/card";

import styles from "../css/pagestructor.module.scss";

// import content from "../content/home/content.json";
// import videoContent from "../content/video/content.json";
// import Carousel from "@ingka/carousel";
// import videoStyling from "../css/videoCarousels.module.scss";

const Home = ({ navBar, props }) => {
  // const { toggle, setToggle, offset, width, jobs } = props;
  const { toggle, setToggle, offset, width } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar width={width} offset={offset} toggle={toggle} setToggle={setToggle} navBar={navBar} />
      <div style={{ backgroundColor: "white", overflow: "hidden" }}>
        {!toggle && (
          // <>
          //   <HeaderVideo header={content.header} offset={offset} />
          //   <div>
          //     <div className={styles.flexColWrapper} id="home_join">
          //       <div className={styles.flexChildImage} style={{ display: "flex", flexDirection: "column" }}>
          //         <VideoLinkin video={videoContent.VideoLinkedin} />
          //       </div>
          //       <div className={styles.flexChildText}>
          //         <Information info={content.information[0]} />
          //       </div>
          //     </div>
          //     <div className={`${styles.blueBackground}`} style={{ paddingBottom: "100px" }}>
          //       <JobCards jobs={jobs} />
          //     </div>
          //     <div id="home_deliver">
          //       <Screws screwNr={1} />
          //       <div className={`${styles.flexColWrapper} ${styles.grayBackground}`}>
          //         <div className={styles.flexChildImage}>
          //           <ImageRound data={content.image[0]} />
          //         </div>
          //         <div className={styles.flexChildText}>
          //           <Information info={content.information[1]} />
          //         </div>
          //       </div>
          //     </div>
          //     <div className={videoStyling["videos-container"]}>
          //       <h2>Get to know our colleagues</h2>
          //       <Carousel
          //         itemClass={videoStyling["videos-carousel-item"]}
          //         className={videoStyling["videos-carousel"]}
          //         id="colleagues-carousel"
          //       >
          //         {videoContent.VideoContent.videos.map((vid) => {
          //           return (
          //             <video key={vid.png} className={videoStyling.vid} poster={vid.png} controls preload={"none"}>
          //               <source src={vid.src} />
          //             </video>
          //           );
          //         })}
          //       </Carousel>
          //     </div>
          //   </div>
          //   <IkeaCounters offset={offset} />
          //   <div>
          //     <JoinUs />
          //   </div>
          //   <div />
          //   <Footer />
          // </>
          <div className={styles.cardContainerWrapper}>
            <div className={styles.cardContainer}>
              <a href="https://tech.ingka.com" className={styles.card}>
                <div className={styles.cardTextBox}>
                  <h1>We have moved</h1>
                  <p>
                    Join.ingka.com has moved its content to the new page tech.ingka.com.
                    <br />
                    <br /> Visit our new page to read more about Ingka Digital, to subscribe to our job opportunities,
                    or to get the latest news about upcoming student and trainee programmes.
                  </p>
                  <div className={styles.cardCTABox}>
                    <Button type="emphasised" iconOnly ssrIcon={arrowRight}></Button>
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: "url(/images/headerImg/210422_8047_ikea.jpg)",
                  }}
                  className={styles.cardImageBox}
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
