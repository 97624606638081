import React, { useState } from "react";
import styles from "../css/navbar.module.scss";

const NavBar = ({ toggle, setToggle, offset }) => {
  const [onHover] = useState(false);

  return (
    <>
      <div
        className={`${toggle ? styles.fullMenu : styles.wrapper}`}
        id={!toggle && (offset > 0) | (onHover !== false) && styles.backgroundImage}
        style={{ background: "rgba(2, 0, 36, 1)" }}
      >
        <div className={styles.flexChild}>
          <ul tabIndex="0" className={styles.list}>
            {!toggle && (
              <a href="/" className={styles.logoWrapper}>
                <img src="/logotype/logo_white.svg" className={styles.logotype} />
              </a>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavBar;
