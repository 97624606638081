import { isTablet, isBrowser, isMobileOnly } from "react-device-detect";

/**
 * Send analytics to Analytics table
 * @param {string} currentLocation            - To which page they navigated.
 * @param {string} [previousLocation]  - From which internal page they navigated.
 * @param {string} [targetUrl]         - URL of the target when going to an external page.
 */
const logAnalytics = async (currentLocation, previousLocation, targetUrl) => {
  /**
   *  From BQ schema:
   *  Field name        Type      Mode      Description
   *  sessionId         STRING    REQUIRED	Identifies a session, created when they entered the site and ends when they left the site.
   *  timestamp         TIMESTAMP REQUIRED	Timestamp of navigation event.
   *  externalReferrer  STRING    NULLABLE	From which external source they were referred.
   *  internalReferrer  STRING    NULLABLE	From which internal page they navigated.
   *  path              STRING    REQUIRED	To which page they navigated.
   *  deviceType        STRING    NULLABLE	Desktop, mobile or tablet. 'Unknown' if their device type can't be identified.
   *  targetUrl         STRING    NULLABLE	URL of the target when going to an external page.
   */
  const navigationState = {
    sessionId: sessionStorage.getItem("sessionId"),
    internalReferrer: previousLocation ? previousLocation : null,
    externalReferrer: document.referrer ? document.referrer : null,
    path: currentLocation,
    targetUrl: targetUrl ? targetUrl : null,
    timestamp: new Date(),
    deviceType: isMobileOnly ? "mobile" : isTablet ? "tablet" : isBrowser ? "desktop" : "unknown",
  };

  await fetch(`${process.env.REACT_APP_API_URL}/analytics`, {
    method: "POST",
    body: JSON.stringify([navigationState]),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * Adds event listner to all links on the page
 * and modifies so they open in a new tab and send analytics.
 *
 * @param {string} currentLocation         - To which page they navigated.
 * @param {string} [previousLocation] - From which internal page they navigated.
 */
const addEventListenersForExternalLinks = (currentLocation, previousLocation) => {
  const externalLinks = document.querySelectorAll(".externalLink");

  for (const link of externalLinks) {
    link.addEventListener("click", async (e) => {
      e.preventDefault();
      window.open(e.target.href, "_blank");
      await logAnalytics(currentLocation, previousLocation, e.target.href);
    });
  }
};

export { logAnalytics, addEventListenersForExternalLinks };
