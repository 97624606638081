import React from "react";
import styles from "../css/findjob.module.scss";
import { useHistory } from "react-router-dom";

const FindOpportunities = ({ navbar, color, offset }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/student/opportunities");
  };

  return (
    <div className={styles.wrapper}>
      <button
        id={offset > 0 ? styles.whitePadding : undefined}
        style={color && { backgroundColor: "#071a53", color: "white" }}
        onClick={handleClick}
        tabIndex="0"
        className={navbar ? styles.findJobButtonNav : styles.findJobButton}
      >
        See Opportunities
      </button>
    </div>
  );
};

export default FindOpportunities;
