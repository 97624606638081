import React from "react";
import styles from "../css/information.module.scss";
import parse from "html-react-parser";

const Information = ({ info, placeRight, placeCenter }) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          {info.title && (
            <h2
              tabIndex="0"
              className={`${styles.title} ${placeRight && styles.right} ${placeCenter && styles.center}`}
            >
              {parse(info.title)}
            </h2>
          )}
          {info.text &&
            info.text.map((txt, i) => {
              return (
                <p tabIndex="0" key={txt + i} className={`${styles.text} ${placeRight && styles.right}`}>
                  {parse(txt)}
                </p>
              );
            })}
          {info.list && (
            <ul>
              {info.list.map((list, index) => {
                return (
                  <div key={index}>
                    <li tabIndex="0">{list}</li>
                  </div>
                );
              })}
            </ul>
          )}
          {info.addedInfo && <p className={`${styles.text} ${placeRight && styles.right}`}>{parse(info.addedInfo)}</p>}
          {info.bold && <h3 className={styles.bold}>{parse(info.bold)}</h3>}
          <div className={`${styles.barDivide} ${placeRight && styles.barRight}`}></div>
        </div>
      </div>
    </>
  );
};

export default Information;
