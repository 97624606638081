import React from "react";

import FindJob from "./FindJob";
import FindOpportunities from "./FindOpportunities";
import ApplyNow from "./ApplyNow";
import styles from "../css/header.module.scss";

const HeaderImage = ({ header, removeFindJob, applyNow, avatureLink, offset, url, showFindOpportunities }) => {
  const renderButtons = () => {
    if (applyNow) {
      return <ApplyNow applyNow={applyNow} avatureLink={avatureLink} />;
    }
    if (removeFindJob) {
      return <></>;
    }
    if (showFindOpportunities) {
      return <FindOpportunities />;
    }
    return <FindJob url={url} />;
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.blackShadowImage}></div>
      <div className={styles.textWrapper}>
        <h1 tabIndex="0" className={styles.title}>
          {header.title}
        </h1>
        {header.slogan && (
          <h2 tabIndex="0" className={styles.slogan}>
            {header.slogan}
          </h2>
        )}
        {renderButtons()}
      </div>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={header.srcHeader}
          alt={header.srcAlt}
          style={{ top: offset, objectFit: "cover" }}
        ></img>
      </div>
    </section>
  );
};
export default HeaderImage;
