import React from "react";
import styles from "../css/footer.module.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className={styles.wrapper}>
        <h3 tabIndex="0">Follow us on social media</h3>
        <div className={styles.iconWrapper}>
          <a tabIndex="0" aria-label="Go to Ingka Facebook" href="https://www.facebook.com/IKEA">
            <button aria-label="Facebook" className={styles.removeButtonCSS} style={{ marginRight: "50px" }}>
              <svg width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.6238 22.5L20.7128 15.2609H13.904V10.5633C13.904 8.58281 14.8552 6.65234 17.9046 6.65234H21V0.489063C21 0.489063 18.191 0 15.5053 0C9.89808 0 6.23292 3.46719 6.23292 9.74375V15.2609H0V22.5H6.23292V40H13.904V22.5H19.6238Z"
                  fill="white"
                />
              </svg>
            </button>
          </a>
          <a tabIndex="0" aria-label="Go to Ingka linkedin" href="https://linkedin.com/company/ikea">
            <button aria-label="linkedin" className={styles.removeButtonCSS}>
              <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.05821 36.7492H0.594643V12.2136H8.05821V36.7492ZM4.32241 8.86676C1.9358 8.86676 0 6.84879 0 4.41246C1.70823e-08 3.2422 0.455396 2.11988 1.266 1.29238C2.07661 0.464883 3.17604 0 4.32241 0C5.46879 0 6.56821 0.464883 7.37882 1.29238C8.18943 2.11988 8.64482 3.2422 8.64482 4.41246C8.64482 6.84879 6.70821 8.86676 4.32241 8.86676ZM35.992 36.7492H28.5445V24.8054C28.5445 21.9589 28.4882 18.3086 24.664 18.3086C20.7836 18.3086 20.1889 21.4011 20.1889 24.6003V36.7492H12.7334V12.2136H19.8916V15.5605H19.9961C20.9925 13.6328 23.4265 11.5984 27.0579 11.5984C34.6114 11.5984 36 16.6761 36 23.2714V36.7492H35.992Z"
                  fill="white"
                />
              </svg>
            </button>
          </a>
        </div>
        <p tabIndex="0">
          © Ingka Holding B.V. {year}
          <br />
          Some images by courtesy of Inter IKEA Systems B.V.
        </p>
        <ul className={styles.footerBar}>
          <li>
            {" "}
            <a tabIndex="0" href="/">
              Home
            </a>
          </li>
          <li>
            <a tabIndex="0" href="https://www.ingka.com/terms-of-use/">
              Terms of use{" "}
            </a>
          </li>
          <li>
            <a tabIndex="0" href="/cookiepolicy">
              Cookie policy
            </a>
          </li>
          <li>
            <a
              tabIndex="0"
              href="https://www.ikea.com/media/special/jobs-at-ikea/global-recruitment-privacy-policy/Privacy_Policies_for_Avature/Sweden_en_INGKA_Privacy_Notice_for_E_recruitment_using_Avature.pdf"
            >
              Privacy policy
            </a>
          </li>
          <li>
            <a tabIndex="0" href="https://www.ingka.com/corporate-information/">
              Corporate information{" "}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
